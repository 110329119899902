import styled, { css } from 'styled-components';

export const Outer = styled.div<{ background: string; hasPadding: boolean }>`
  ${({ hasPadding, background }) => css`
    padding: ${hasPadding ? '24px 32px' : 0};
    background-color: ${background};
    transition: 0.15s padding;
  `}
`;

export const Inner = styled.div`
  max-width: ${({ theme }) => theme.sizes.maxContentWidth};
  margin: 0 auto;
`;
