import { NextPage } from 'next';
import { H1 } from '../components/Typography';
import Section from '../components/Section';
import { logoutAction } from '../utils/helpers';

const Home: NextPage = () => (
  <Section>
    <H1>SupertutorTV Admin Portal</H1>

    <button onClick={logoutAction}>click to logout</button>
  </Section>
);

export default Home;
